import React, { useState } from "react";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import styled from "styled-components";
import { Button, ScrollBar } from "../../Theme/Hyper";
import SelectUI from "../UI/Form/SelectUI";

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

const Scroll = styled(ScrollBar)`
  max-height: 400px;
  overflow-y: auto;
`;
export default function SeasonEdit({ season, teams, competitions, onChange }) {
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedCompetition, setSelectedCompetition] = useState();

  function updateSeason(season) {
    onChange(season);
  }

  return (
    <Grid style={{ width: "100%" }}>
      <Grid.Unit size={1 / 1}>
        <SelectUI
          value={
            (selectedCompetition && {
              value: selectedCompetition,
              label: selectedCompetition.name,
            }) ||
            (competitions.find((c) => c._id === season.competition) && {
              value: competitions.find((c) => c._id === season.competition),
              label: competitions.find((c) => c._id === season.competition)
                .name,
            })
          }
          options={competitions.map((c) => {
            return { value: c, label: c.name };
          })}
          placeholder="Select Competitions"
          onChange={(comp) => {
            let newSeason = { ...season, competition: comp._id };
            updateSeason(newSeason);
            setSelectedCompetition(comp);
          }}
        />
      </Grid.Unit>
      <Grid.Unit size={1 / 1}>
        <Input
          value={season.name}
          title="Name"
          onChange={(e) => {
            let newSeason = { ...season, name: e.target.value };
            updateSeason(newSeason);
          }}
        />
      </Grid.Unit>
      <Grid.Unit size={1 / 1}>
        <Input disabled={true} value={season?.opta_ID} title="OPTA ID" />
      </Grid.Unit>

      {/* <Grid.Unit size={1 / 1}>
        <Grid>
          <Grid.Unit size={2 / 3}>
            <SelectUI
              value={
                selectedTeam && {
                  value: selectedTeam,
                  label: selectedTeam.name,
                }
              }
              options={teams
                .filter((t) => season.teams.indexOf(t._id) === -1)
                .map((c) => {
                  return { value: c, label: c.name };
                })}
              placeholder="Select Team"
              onChange={(comp) => {
                setSelectedTeam(comp);
              }}
            />
          </Grid.Unit>
          <Grid.Unit size={1 / 3}>
            {selectedTeam && (
              <Button
                onClick={() => {
                  let newSeason = {
                    ...season,
                    teams: [...season.teams, selectedTeam._id],
                  };
                  updateSeason(newSeason);
                }}
              >
                Add Team
              </Button>
            )}
          </Grid.Unit>
        </Grid>
      </Grid.Unit>
      <Grid.Unit size={1 / 1}>
        <Scroll>
          <Table>
            <thead>
              <tr>
                <TH></TH>
                <TH></TH>
              </tr>
            </thead>
            <tbody>
              {teams &&
                season &&
                season.teams.map((t) => {
                  let team = teams.find((te) => te._id === t);
                  return (
                    team && (
                      <tr>
                        <td>{team.short_name}</td>
                        <td>
                          <Button
                            onClick={() => {
                              let newSeason = {
                                ...season,
                                teams: [...season.teams].filter(
                                  (te) => te !== t
                                ),
                              };
                              updateSeason(newSeason);
                            }}
                          >
                            Remove Team
                          </Button>
                        </td>
                      </tr>
                    )
                  );
                })}
            </tbody>
          </Table>
        </Scroll>
      </Grid.Unit> */}
    </Grid>
  );
}
